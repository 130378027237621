import * as React from 'react';
import { graphql } from 'gatsby';
import PageLayout from '../components/_Shared/PageLayout/PageLayout';
import ServicePage from '../components/ServicePage/ServicePage';
import 'normalize.css';
import '../globalStyles/_fonts.scss';
import '../globalStyles/_styles.scss';
import { ServicePageData } from '../components/ServicePage/types';

export const query = graphql`
  query MyCustomMobileApplication($id: String) {
    sanityServices(id: { eq: $id }) {
      _rawTechnologiesContent
      title
      description
      image {
        asset {
          url
        }
      }
      advantages {
        number
        step
      }
      seo {
        description
        title
      }
      serviceListContent {
        title
        description
        icon {
          alt
          image {
            asset {
              url
            }
          }
        }
        image {
          alt
          image {
            asset {
              url
            }
          }
        }
        serviceList {
          answer
          title
        }
      }
      solutionListContent {
        title
        listDescription
        secondaryListDescription
        listContent {
          features {
            title
            description
            icon {
              image {
                asset {
                  url
                }
              }
            }
          }
        }
        secondaryListContent {
          features {
            title
            description
            icon {
              image {
                asset {
                  url
                }
              }
            }
          }
        }
      }
      tools {
        title
        image {
          alt
          image {
            asset {
              url
            }
          }
        }
      }
      blockWithCardContent {
        cardTitle
        cardIcon {
          asset {
            url
          }
        }
      }
      faqMarkup {
        question
        _rawAnswers
      }
      userForm {
        managerName
        managerPosition
        description
        image {
          asset {
            url
          }
        }
        openFormLinks {
          title
          image {
            caption
            alt
            image {
              asset {
                url
              }
            }
          }
        }
      }
      ourProcess {
        _rawContent
        image {
          asset {
            url
          }
        }
        processStepsTitle
        processSteps {
          title
          icons {
            image {
              asset {
                url
              }
            }
            alt
          }
        }
      }
      caseStudySlider {
        title
        description
        slug {
          current
        }
        mainImage {
          asset {
            url
          }
        }
        technologiesImage {
          asset {
            url
          }
        }
      }
    }
    allSanityFooter {
      nodes {
        title
        footerRow {
          title
          link
        }
      }
    }
  }
`;

const CustomMobileApplicationPage = ({ data }: ServicePageData) => {
  const seoTitle = data.sanityServices.seo.title;
  const seoDescription = data.sanityServices.seo.description;
  const microMarkup = data.sanityServices.faqMarkup;
  const { allSanityFooter } = data;

  return (
    <PageLayout footerData={allSanityFooter} markData={microMarkup} title={seoTitle} description={seoDescription}>
      <ServicePage data={data} />
    </PageLayout>
  );
};

export default CustomMobileApplicationPage;
