import React from 'react';
import './Card.scss';

interface CardProps {
  index?: number;
  title: string;
  src: string;
  alt: string;
}

const Card = ({ index, src, alt, title }: CardProps) => {
  return (
    <div key={index} className="cardBlock__item">
      <img loading="lazy" src={src} alt={alt} width="56px" height="56px" />
      <p>{title}</p>
    </div>
  );
};

export default Card;
