import React, { FC } from 'react';
import './Tools.scss';
import { ToolsData } from './types';

interface ToolsProps {
  data: ToolsData;
  title?: string;
}

const Tools: FC<ToolsProps> = ({ data }) => {
  const { title, image } = data;
  const imageSrc = image.image.asset.url;
  const alt = image.alt;

  return (
    <div className="tools">
      <div className="tools__container">
        <h2>{title}</h2>
        <picture className="work_process">
          <source srcSet={imageSrc} media="(min-width: 1200px)" />
          <source srcSet={imageSrc} media="(min-width: 768px)" width="670px" />
          <source srcSet={imageSrc} media="(min-width: 320px)" width="300px" />
          <img loading="lazy" src={imageSrc} alt={alt} />
        </picture>
      </div>
    </div>
  );
};

export default Tools;
