import * as React from 'react';
import { useState, useEffect } from 'react';
import './BlockWithCard.scss';
import Card from '../Card/Card';
import { BTN_STYLES, Button } from '../Button/Button';
import DevServicesCardsData from './types';
import { navigate } from 'gatsby';
import Modal from '../Modal/Modal';

interface BlockWithCardProps {
  title?: string;
  data?: DevServicesCardsData[];
  headerClassName?: string;
}

const BlockWithCard = ({
  title = 'Lunka Full-Cycle App Development Services',
  data,
  headerClassName,
}: BlockWithCardProps) => {
  const [isIndustriesPage, setIsIndustriesPage] = useState<boolean>(false);
  const [isServicePage, setIsServices] = useState<boolean>(false);
  const [isHomePage, setIsHomePage] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    if (document.querySelector('.industries')) {
      setIsIndustriesPage(true);
    }

    if (document.querySelector('.mainHome')) {
      setIsHomePage(true);
    }

    if (document.querySelector('.service')) {
      setIsServices(true);
    }
  }, [isIndustriesPage, isServicePage, isHomePage]);

  return (
    <section className="cardBlock">
      <div className="cardBlock__container">
        <div className="cardBlock__wrapper">
          <h2 className={headerClassName || 'headerStart'}>{title}</h2>
          <div className="cardBlock__cards">
            {data?.map(
              (
                {
                  cardTitle,
                  cardIcon: {
                    asset: { url },
                  },
                },
                index: number
              ) => {
                return <Card key={index} src={url} alt="solution icon" title={cardTitle} />;
              }
            )}
          </div>
          <Button
            title={isIndustriesPage ? 'Contact our consultant' : 'Estimate Project'}
            className={BTN_STYLES.BTN_PRIMARY_LARGE}
            onClick={() => (isHomePage ? setIsVisible(true) : navigate('#yourIdea'))}
          />
        </div>
      </div>
      <Modal visible={isVisible} onClose={() => setIsVisible(false)} />
    </section>
  );
};

export default BlockWithCard;
