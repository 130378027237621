import * as React from 'react';
import Main, { MAIN_BACKGROUND_STYLES } from '../_Shared/Main/Main';
import { ServicePageData } from './types';
import ServicesList from '../_Shared/ServicesList/ServicesList';
import BlockWithCard from '../_Shared/BlockWithCard/BlockWithCard';
import TechnologiesStack from '../_Shared/TechnologiesStack/TechnologiesStack';
import Slider from '../_Shared/Slider/Slider';
import OurProcess from '../_Shared/OurProcess/OurProcess';
import Cooperation from '../Home/Cooperation/Cooperation';
import Testimonials from '../_Shared/Testimonials/Testimonials';
import AccordionWrapper from '../_Shared/AccordionWrapper/AccordionWrapper';
import YourIdea from '../_Shared/YourIdea/YourIdea';
import Advantages from '../NewIndustriesPage/Advantages/Advantages';
import Idea from '../Home/Idea/Idea';
import { BTN_STYLES } from '../_Shared/Button/Button';
import Tools from '../_Shared/Tools/Tools';
import FeaturesToBuildSolutions from '../NewIndustriesPage/FeaturesToBuildSolutions/FeaturesToBuildSolutions';

const ServicePage = ({ data }: ServicePageData) => {
  const title = data.sanityServices.title;
  const description = data.sanityServices.description;
  const servicesListData = data.sanityServices.serviceListContent;
  const blockWithCardData = data.sanityServices.blockWithCardContent;
  const faqData = data.sanityServices.faqMarkup;
  const openForm = data.sanityServices.userForm;
  const technologies = data.sanityServices._rawTechnologiesContent;
  const sliderData = data.sanityServices.caseStudySlider;
  const mainImage = data.sanityServices?.image?.asset?.url;
  const advantages = data.sanityServices?.advantages;
  const ourProcess = data.sanityServices.ourProcess;
  const solutionListContent = data.sanityServices.solutionListContent;
  const tools = data.sanityServices.tools;
  const isSecondaryClassName = technologies?.secondaryClassName;

  return (
    <>
      <Main
        className={mainImage ? MAIN_BACKGROUND_STYLES.NEW_INDUSTRIES : MAIN_BACKGROUND_STYLES.SERVICES}
        title={title}
        description={description}
        btnTitle="Contact us"
        classNameButton={BTN_STYLES.BTN_PRIMARY_LARGE}
        image={mainImage && mainImage}
      />
      {solutionListContent && <FeaturesToBuildSolutions subtitleClassName="subtitle" data={solutionListContent} />}
      {servicesListData && <ServicesList data={servicesListData} />}
      {blockWithCardData.length > 0 && <BlockWithCard data={blockWithCardData} title="Why Lunka" />}
      {advantages?.length > 0 && <Advantages data={advantages} />}
      {advantages?.length > 0 && <Idea className="ideaCustom" />}
      {technologies && <TechnologiesStack data={data && technologies} secondaryClassName={isSecondaryClassName} />}
      {tools && <Tools data={tools} />}
      <Slider data={sliderData} />
      <OurProcess data={ourProcess} />
      <Cooperation />
      <Testimonials />
      <AccordionWrapper data={faqData} />
      <YourIdea data={openForm} />
    </>
  );
};

export default ServicePage;
